import { FiltersState } from './'

type FiltersActionType =
    | { type: '[FILTERS] UPDATE_SEARCH'; payload: { search: string } }
    | { type: '[FILTERS] UPDATE_STATUS'; payload: { status: string } }
    | {
          type: '[FILTERS] UPDATE_COMMERCIAL_ID'
          payload: { commercial_id: string }
      }
    | {
          type: '[FILTERS] UPDATE_DISTRIBUTOR_ID'
          payload: { distributor_id: string }
      }
    | { type: '[FILTERS] UPDATE_START_DATE'; payload: { start_date: string } }
    | { type: '[FILTERS] UPDATE_END_DATE'; payload: { end_date: string } }
    | { type: '[FILTERS] RESET_STATE' }

export const filtersReducer = (
    state: FiltersState,
    action: FiltersActionType,
): FiltersState => {
    switch (action.type) {
        case '[FILTERS] UPDATE_SEARCH': {
            return {
                ...state,
                search: action.payload.search,
            }
        }

        case '[FILTERS] UPDATE_STATUS': {
            return {
                ...state,
                status: action.payload.status,
            }
        }

        case '[FILTERS] UPDATE_COMMERCIAL_ID': {
            return {
                ...state,
                commercial_id: action.payload.commercial_id,
            }
        }

        case '[FILTERS] UPDATE_DISTRIBUTOR_ID': {
            return {
                ...state,
                distributor_id: action.payload.distributor_id,
            }
        }

        case '[FILTERS] UPDATE_START_DATE': {
            return {
                ...state,
                start_date: action.payload.start_date,
            }
        }

        case '[FILTERS] UPDATE_END_DATE': {
            return {
                ...state,
                end_date: action.payload.end_date,
            }
        }

        case '[FILTERS] RESET_STATE': {
            return {
                search: '',
                commercial_id: '',
                distributor_id: '',
                end_date: '',
                start_date: '',
                status: '',
            }
        }

        default:
            return state
    }
}
