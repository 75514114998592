import { createContext } from 'react'
import { FiltersState } from './FiltersProvider'

interface ContextProps {
    filters: FiltersState

    updateSearchFilter: (value) => void
    updateStatusFilter: (value) => void
    updateDistributorFilter: (value) => void
    updateCommercialFilter: (value) => void
    updateStartDateFilter: (value) => void
    updateEndDateFilter: (value) => void
    resetState: () => void
}

export const FiltersContext = createContext({} as ContextProps)
